"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEASONS = void 0;
var SEASONS;
(function (SEASONS) {
    SEASONS["SPRING"] = "spring";
    SEASONS["SUMMER"] = "summer";
    SEASONS["AUTUMN"] = "autumn";
    SEASONS["WINTER"] = "winter";
})(SEASONS = exports.SEASONS || (exports.SEASONS = {}));
