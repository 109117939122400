import * as yup from "yup";
import { VulnerabilityFormType } from "../../../context/vulnerability-areas";
import { Schema } from "yup";

export const VulnerabilitySchema = yup.lazy<yup.Schema<VulnerabilityFormType>>(
  (fullConfig) =>
    yup.object({
      speed: yup.number().required(),
      length: yup.number().required(),
      wingspan: yup.number().required(),
      nocturnalActivity: yup.number().required(),
      flight_type: yup.string().oneOf(["flapping", "gliding"]).required(),

      blades: yup.number().required(),
      radius: yup.number().required(),
      pitch: yup.number().required(),
      chord: yup.number().required(),
      bladeFormRadiusArray: yup
        .array()
        .of(yup.number().required().min(0).max(1))
        .required()
        .length(fullConfig.bladeFormChordsArray?.length || 0),
      bladeFormChordsArray: yup
        .array()
        .of(yup.number().required())
        .required()
        .length(fullConfig.bladeFormRadiusArray?.length || 0),
      omega: yup.number().required(),
      numberOfTurbines: yup.number().required(),

      hubheight: yup.number().required(),
      offset: yup.number().required(),
      timeOfWorkPerMonth: yup.array().of(yup.number().required()).length(12),

      xinc: yup.number().required(),
      yinc: yup.number().required(),
      updownProportion: yup.number().required().min(0).max(1),
    })
);
