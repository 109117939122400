"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateIntervalsToConfig = exports.getDefaultIntervalKey = exports.getDateIntervals = void 0;
const getDateIntervals = (yearsIntervals, monthIntervals, intervalsInMonth) => yearsIntervals.flatMap((years) => {
    const [yearStart, yearEnd] = typeof years === "number" ? [years, years] : years;
    const res = [];
    for (let year = yearStart; year <= yearEnd; year++) {
        const currentYearIntervals = monthIntervals.flatMap((months) => {
            const [monthStart, monthEnd] = typeof months === "number" ? [months, months] : months;
            const monthsRes = [];
            if (!intervalsInMonth) {
                res.push([
                    new Date(year, monthStart, 1),
                    new Date(year, monthEnd + 1, 0),
                ]);
            }
            else {
                for (let month = monthStart; month <= monthEnd; month++) {
                    const currentMonthIntervals = intervalsInMonth.map(([dateStart, dateEnd]) => {
                        let start = new Date(year, month, dateStart);
                        let end = new Date(year, month);
                        if (typeof dateEnd === "string") {
                            end = new Date(year, month + 1, 0);
                        }
                        else {
                            end.setDate(dateEnd);
                        }
                        return [start, end];
                    });
                    monthsRes.push(...currentMonthIntervals);
                }
            }
            return monthsRes;
        });
        res.push(...currentYearIntervals);
    }
    return res;
});
exports.getDateIntervals = getDateIntervals;
const getDefaultIntervalKey = (dateInterval) => dateInterval
    .map((it) => it.toLocaleDateString("ru").split(".").join(""))
    .join("_");
exports.getDefaultIntervalKey = getDefaultIntervalKey;
const dateIntervalsToConfig = (dateIntervals) => dateIntervals.reduce((acc, interval) => {
    acc[(0, exports.getDefaultIntervalKey)(interval)] = [interval];
    return acc;
}, {});
exports.dateIntervalsToConfig = dateIntervalsToConfig;
