import styled from "@emotion/styled";
import { Button, TextField, TextFieldProps, Typography } from "@mui/material";
import { memo } from "react";

export const MigrationVulnerabilityContent = styled("div")`
  padding: 40px;
`;
export const MigrationVulnerabilityFromsContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;
export const MigrationVulnerabilityContentColumn = styled("div")`
  flex: 1;
`;
export const MigrationVulnerabilityTextFieldComponent = (
  props: TextFieldProps
) => <TextField {...props} size={"small"} />;
export const MigrationVulnerabilityTextField = memo(styled(
  MigrationVulnerabilityTextFieldComponent
)`
  flex: 1;
  margin-bottom: 8px;
`);
export const MigrationVulnerabilityButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;

export const MigrationVulnerabilityCancelButton = styled(Button)`
  display: flex;
  flex-direction: row;
`;

export const MigrationVulnerabilitySaveButton = styled(Button)`
  display: flex;
  flex-direction: row;
`;

export const MigrationVulnerabilityCalculateButton = styled(Button)`
  display: flex;
  flex-direction: row;
`;
export const MigrationVulnerabilityConfigButton = styled(Button)<{
  $error?: boolean | string | number;
}>`
  display: flex;
  flex-direction: row;
  color: ${(props) => (props.$error ? "red;" : "")};
`;

export const MigrationVulnerabilityConfigError = styled(Typography)`
  color: red;
`;
