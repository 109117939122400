import { CommonPaper } from "@/components/common/common";
import React, { useEffect, useState } from "react";
import { useMigrationVulnerabilityContext } from "../../context/vulnerability-areas";
import { Button } from "@mui/material";
import { MigrationAreaVulnerabilityModal } from "./modal";
import { GoogleMapObject } from "@/utils/geometry/map/useDrawGeojson";
import {
  MigrationVulnerabilityConfigButton,
  MigrationVulnerabilityConfigError,
} from "./style";
import { useTranslations } from "@/utils/translations";

export const MigrationsVulnerability = ({
  mapObjects,
}: {
  mapObjects: GoogleMapObject[];
}) => {
  const { selectedAreas, form, isLoading } = useMigrationVulnerabilityContext();
  const [openVulnerability, setOpenVulnerability] = useState(false);
  useEffect(() => {
    for (let area of selectedAreas) {
      mapObjects[area.index].set("strokeColor", "green");
      mapObjects[area.index].set("zIndex", "2");
    }
    return () => {
      for (let area of selectedAreas) {
        mapObjects[area.index].set("strokeColor", "black");
      }
    };
  }, [selectedAreas]);
  const t = useTranslations();

  return (
    <CommonPaper>
      <div>
        <div>{t["migrations.vulnerability"]}</div>
        <div>
          {t["migrations.vulnerability-selecred-areas"]} {selectedAreas.length}
        </div>
        <div>
          <MigrationVulnerabilityConfigButton
            $error={!form?.isValid}
            onClick={() => setOpenVulnerability(true)}
          >
            {t["migrations.vulnerability-config"]}
          </MigrationVulnerabilityConfigButton>
          {!form?.isValid && (
            <MigrationVulnerabilityConfigError>
              {t["migrations.vulnerability-errors-in-config"]}
            </MigrationVulnerabilityConfigError>
          )}
        </div>
        {openVulnerability && (
          <MigrationAreaVulnerabilityModal
            onCancelClick={() => setOpenVulnerability(false)}
          />
        )}
        {form && (
          <Button
            disabled={!selectedAreas.length || isLoading}
            onClick={() => form.submitForm()}
          >
            {t["migrations.vulnerability-run"]}{" "}
          </Button>
        )}
      </div>
    </CommonPaper>
  );
};
